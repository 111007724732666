import deepmerge from "@node/deepmerge";

let locales = {};

const modules = import.meta.glob("@o-din-maintenance/../../config/locales/**/*.yml", { eager: true })

for (const path in modules) {
  locales = deepmerge(locales, modules[path].default);
}

import store from "@o-din-maintenance/src/maintenance/store";
import menu from "@o-din-maintenance/src/maintenance/menu.json";

// ##= MAINTENANCE
// Issues
import IssuesIndex from "@o-din-maintenance/src/views/maintenance_issues/Index.vue";
import IssuesArchive from "@o-din-maintenance/src/views/maintenance_issues/Archive.vue";
import IssueForm from "@o-din-maintenance/src/views/maintenance_issues/Form.vue";
import IssueShow from "@o-din-maintenance/src/views/maintenance_issues/Show.vue";
import PrintForm from "@o-din-maintenance/src/views/maintenance_issues/Print.vue";
// Services
import ServicesIndex from "@o-din-maintenance/src/views/services/Index.vue";
import ServicesForm from "@o-din-maintenance/src/views/services/Form.vue";
// WorkCategories
import WorkCategoriesIndex from "@o-din-maintenance/src/views/work_categories/Index.vue";
import WorkCategoryForm from "@o-din-maintenance/src/views/work_categories/Form.vue";

export default {
  routes: [
    { path: '/maintenance_issues/', component: IssuesIndex },
    { path: '/maintenance_issues/archive/', component: IssuesArchive },
    { path: '/maintenance_issues/new/', component: IssueForm },
    { path: '/maintenance_issues/:id', component: IssueShow },
    { path: '/maintenance_issues/:id/edit', component: IssueForm },
    { path: '/maintenance_issues/:id/print', component: PrintForm },
    { path: '/work_categories', component: WorkCategoriesIndex },
    { path: '/work_categories/new', component: WorkCategoryForm },
    { path: '/work_categories/:id/edit', component: WorkCategoryForm },
    { path: '/services/', component: ServicesIndex },
    { path: '/services/:id/edit', component: ServicesForm },
    { path: '/services/new', component: ServicesForm }

  ],
  locales,
  store,
  menu: [menu]
}
