import adapter from "@o-din/src/adapter";

export default {
  maintenance_issues: {
    index: function(params = {}) {
      return adapter.get("api/v4/maintenance_issues.json", { params: params })
    },
    show: (id) => {
      return adapter.get(`api/v4/maintenance_issues/${id}.json`)
    },
    create: (params) => {
      return adapter.post("api/v4/maintenance_issues.json?src=web", params)
    },
    archive: function(params = {}) {
      return adapter.get("api/v4/maintenance_issues/archive.json", { params: params })
    },
    update: function(id, params = {}) {
      return adapter.patch(`api/v4/maintenance_issues/${id}.json?src=web`, params)
    },
    executors: function(id) {
      return adapter.get(`api/v4/maintenance_issues/${id}/executors_collection.json`)
    },
    destroy: function(id) {
      return adapter.delete(`api/v4/maintenance_issues/${id}.json`)
    },
  },
  services: {
    index: function(params = {}) {
      return adapter.get("api/v4/services.json", { params: params })
    },
    all_services: function(params = {}) {
      return adapter.get("api/v4/all_services.json", { params: params })
    },
    get: function(id) {
      return adapter.get(`api/v4/services/${id}.json`)
    },
    create: function(params = {}) {
      return adapter.post("api/v4/services.json", params )
    },
    update: function(id, params = {}) {
      return adapter.patch(`api/v4/services/${id}.json`, params)
    },
    destroy: function(id, params = {}) {
      return adapter.delete(`api/v4/services/${id}.json`)
    }
  },
  work_categories: {
    index: function(params = {}) {
      return adapter.get("api/v4/work_categories.json", { params: params })
    },
    all_work_categories: function(params = {}) {
      return adapter.get('api/v4/all_work_categories.json', { params: params })
    },
    create: function(params = {}) {
      return adapter.post("api/v4/work_categories.json", params)
    },
    update: function(id, params = {}) {
      return adapter.patch(`api/v4/work_categories/${id}.json`, params)
    },
    show: function(id) {
      return adapter.get(`api/v4/work_categories/${id}.json`)
    }
  },
  dashboard_data: {
    index: function(params = {}) {
      return adapter.get("api/v4/dashboard_data.json", { params: params })
    },
    update: function(id, params = {}) {
      return adapter.patch(`api/v4/dashboard_data/${id}.json`, params)
    },
    show: function(id) {
      return adapter.get(`api/v4/dashboard_data/${id}.json`)
    }
  },
  // O1 specifics
  guests_sessions: {
    create_multiple: function(params) {
      return adapter.post('/api/v4/guest_sessions.json', params)
    }
  },
  pay_executors: {
    index: function(params = {}) {
      return adapter.get('/api/v4/users.json', { params: { filters: { role_in: ['chief_engineer', 'director'] } } })
    }
  }
}
