export default [
  { path: '/dashboard', component: () => import("./views/dashboard/Dashboard.vue") },
  { path: '/sign_in/', component: () => import("./views/users/Login.vue") },
  { path: '/sign_up/', component: () => import("./views/users/Registration.vue") },
  { path: '/blacklists/', component: () => import("./views/blacklists/Index.vue") },
  { path: '/blacklists/new', component: () => import("./views/blacklists/Form.vue") },
  { path: '/blacklists/:id/edit', component: () => import("./views/blacklists/Form.vue") },
  { path: '/nfc_tags/', component: () => import("./views/nfc_tags/Index.vue") },
  { path: '/nfc_tags/:id/edit', component: () => import("./views/nfc_tags/Form.vue") },
  { path: '/imports/', component: () => import("./views/imports/Index.vue") },
  { path: '/imports/:id', component: () => import("./views/imports/Show.vue") },
  { path: '/floors/', component: () => import("./views/floors/Index.vue") },
  { path: '/floors/new', component: () => import("./views/floors/Form.vue") },
  { path: '/floors/:id/edit', component: () => import("./views/floors/Form.vue") },
  { path: '/feedbacks/', component: () => import("./views/feedbacks/Index.vue") },
  { path: '/companies/', component: () => import("./views/companies/Index.vue") },
  { path: '/companies/new', component: () => import("./views/companies/Form.vue") },
  { path: '/companies/:id/edit', component: () => import("./views/companies/Form.vue") },
  { path: '/companies/:id', component: () => import("./views/companies/Show.vue") },
  { path: '/issues_exports/', component: () => import("./views/issues_exports/Index.vue") },
  { path: '/rooms/', component: () => import("./views/rooms/Index.vue") },
  { path: '/rooms/new', component: () => import("./views/rooms/Form.vue") },
  { path: '/rooms/:id/edit', component: () => import("./views/rooms/Form.vue") },
  { path: '/rooms/:id', component: () => import("./views/rooms/Show.vue") },
  { path: '/iframes/:src', component: () => import("./views/iframes/Show.vue") },
  { path: '/room_types/', component: () => import("./views/room_types/Index.vue") },
  { path: '/room_types/:id/edit', component: () => import("./views/room_types/Form.vue") },
  { path: '/facilities/', component: () => import("./views/facilities/Index.vue") },
  { path: '/facilities/new', component: () => import("./views/facilities/Form.vue") },
  { path: '/facilities/:id', component: () => import("./views/facilities/Show.vue") },
  { path: '/facilities/:id/edit', component: () => import("./views/facilities/Form.vue") },
  { path: '/buildings', component: () => import("./views/buildings/Index.vue") },
  { path: '/buildings/new', component: () => import("./views/buildings/Form.vue") },
  { path: '/buildings/:id/edit', component: () => import("./views/buildings/Form.vue") },
  { path: '/users/', component: () => import("./views/users/Index.vue") },
  { path: '/users/new', component: () => import("./views/users/Form.vue") },
  { path: '/users/:id/edit', component: () => import("./views/users/Form.vue") },
  { path: '/users/:id', component: () => import("./views/users/Show.vue") },
  { path: '/profile', component: () => import("./views/users/Profile.vue") },
  { path: '/notification_rules', component: () => import("./views/notification_rules/Index.vue") },
  { path: '/dreamhalls', component: () => import("./views/dreamhalls/Index.vue") },
  { path: '/pageNotFound', component: () => import("./components/PageNotFound.vue") },
]
