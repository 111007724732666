const channels = import.meta.glob('./**/*_channel.js', { eager: true })
// Node stuff
import deepmerge from "@node/deepmerge";

// Vue stuff
import Vue from "@node/vue/dist/vue.js";
import VueI18n from "@node/vue-i18n";
import vueDebounce from "@node/vue-debounce";
import VueRouter from "@node/vue-router";
import VueCookies from "@node/vue-cookies";
import ElementUI from "@node/element-ui";
import "@node/element-ui/lib/theme-chalk/index.css";
import "@o-din/src/scss/customize_el_ui.scss";
import ruLocale from "@node/element-ui/lib/locale/lang/ru-RU";
import enLocale from "@node/element-ui/lib/locale/lang/en";
import etLocale from "@node/element-ui/lib/locale/lang/ee";
import esLocale from "@node/element-ui/lib/locale/lang/es";
import ActionCable from "@node/actioncable";
import "@node/highlight.js/lib/common";
import "@node/highlight.js/styles/shades-of-purple.css";

// Base components
import AutoTable from "@o-din/src/components/AutoTable.vue";
import BasePage from "@o-din/src/components/BasePage.vue";
import DropdownMenu from "@o-din/src/components/DropdownMenu.vue";
import DateFilter from "@o-din/src/components/DateFilter.vue";
import ErrorsView from "@o-din/src/components/ErrorsView.vue";
import FilePicker from "@o-din/src/components/FilePicker.vue";
import IconPicker from "@o-din/src/components/IconPicker.vue";
import Modal from "@o-din/src/components/Modal.vue";
import MiniTable from "@o-din/src/components/MiniTable.vue";
import Paginator from "@o-din/src/components/Paginator.vue";
import PerPage from "@o-din/src/components/PerPage.vue";
import RichEditor from "@o-din/src/components/RichEditor.vue";
import SelectFilter from "@o-din/src/components/SelectFilter.vue";
import SortableHeader from "@o-din/src/components/SortableHeader.vue";
import HelpIcon from "@o-din/src/components/HelpIcon.vue";
import BarChart from "@o-din/src/components/charts/BarChart.vue";
import LineChart from "@o-din/src/components/charts/LineChart.vue";

Vue.component("auto-table", AutoTable);
Vue.component("base-page", BasePage);
Vue.component("dropdown-menu", DropdownMenu);
Vue.component("date-filter", DateFilter);
Vue.component("errors-view", ErrorsView);
Vue.component("file-picker", FilePicker);
Vue.component("icon-picker", IconPicker);
Vue.component("help-icon", HelpIcon);
Vue.component("modal", Modal);
Vue.component("mini-table", MiniTable);
Vue.component("rich-editor", RichEditor);
Vue.component("paginator", Paginator);
Vue.component("per-page", PerPage);
Vue.component("rich-editor", RichEditor);
Vue.component("select-filter", SelectFilter);
Vue.component("sortable-header", SortableHeader);
Vue.component("bar-chart", BarChart);
Vue.component("line-chart", LineChart);

Vue.use(VueI18n);
Vue.use(vueDebounce);
Vue.use(VueRouter);

// I18n (from rails locales)
let messages = {};

const modules = import.meta.glob("@o-din/../../config/locales/**/*.yml", { eager: true })

for (const path in modules) {
  messages = deepmerge(messages, modules[path].default);
}

messages = deepmerge(messages, { ru: ruLocale })
messages = deepmerge(messages, { en: enLocale })
messages = deepmerge(messages, { et: etLocale })
messages = deepmerge(messages, { es: esLocale })

if (process.env.HOST == 'localhost') {
  Vue.prototype.$cable = ActionCable.createConsumer(`ws://${process.env.HOST}:3000/cable?token=${VueCookies.get("auth_token")}`);
} else {
  Vue.prototype.$cable = ActionCable.createConsumer(`wss://${window.location.host}/cable?token=${VueCookies.get("auth_token")}`);
}

import core_routes from "@o-din/src/routes";
import configuration_menu from "@o-din/src/configuration_menu.json";
// adapter + store
import root_store from "@o-din/src/store"

export function createApp(el, modules) {
  let routes = core_routes;
  let store = Object.assign({}, root_store)
  let menu = [];

  modules.forEach((item, i) => {
    routes = routes.concat(item.routes || []);
    menu = menu.concat(item.menu || []);
    messages = deepmerge(messages, item.locales || {});
    Object.assign(store, item.store)
  });
  menu = menu.concat([configuration_menu] || []);

  Vue.prototype.$store = store;
  Vue.prototype.$menu = menu;

  const i18n = new VueI18n({ locale: "en", messages });
  Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) });
  const router = new VueRouter({ mode: "history", routes: routes });

  document.addEventListener("DOMContentLoaded", function () {
    if (!!document.querySelector(el)) {
      new Vue({ i18n: i18n, el: el, router: router });
    }
  });
}
